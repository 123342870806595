import { db } from '@/shared/firebase'

export default class multiBatch {
  constructor() {
    this.batchArray = [db.batch()]
    this.operationCounter = 0
    this.batchIndex = 0
  }
  set(path, object, options) {
    this.batchArray[this.batchIndex].set(path, object, options)
    this._batchesSnapshots()
  }

  update(path, object) {
    this.batchArray[this.batchIndex].update(path, object)
    this._batchesSnapshots()
  }

  delete(path) {
    this.batchArray[this.batchIndex].delete(path)
    this._batchesSnapshots()
  }

  _batchesSnapshots() {
    this.operationCounter++

    if (this.operationCounter === 499) {
      this.batchArray.push(db.batch())
      this.batchIndex++
      this.operationCounter = 0
    }
  }

  async commit() {
    return this.batchArray.map(async batch => {
      return await batch.commit()
    })
  }
}
