<template>
  <div v-if="$store.state.organizationsLoaded || initialized">
    <div class="row">
      <form class="col-7" @submit.prevent="saveChanges">
        <h1 class="h2">Newsletter</h1>
        <hr />
        <div class="form-group">
          <input
            v-model="newsletter.subject"
            type="text"
            class="form-control"
            placeholder="Subject"
            :disabled="newsletter.status === 'sent'"
          />
        </div>
        <hr />
        <strong class="d-inline-block mb-2">Guten Tag Frau Mustermann,</strong>
        <div class="form-group">
          <textarea-autosize
            v-model="newsletter.body"
            class="form-control"
            placeholder="Newsletter Text"
            rows="1"
            :disabled="newsletter.status === 'sent'"
          />
        </div>
        Viele Grüße<br /><br />Ihr Emma-Team<br />
        <hr />
        <div v-for="(attachment, index) in newsletter.attachments" :key="index">
          <h5 class="mb-2">Attachment {{ index + 1 }}</h5>
          <div class="row">
            <div class="col-4">
              <input v-model="attachment.filename" type="text" class="form-control" placeholder="Filename" required />
            </div>
            <div class="col-7">
              <input v-model="attachment.href" type="text" class="form-control" placeholder="File URL" required />
            </div>
            <div style="display:flex; align-items:center" class="col-1">
              <button
                @click="newsletter.attachments.splice(index, 1)"
                type="button"
                class="btn btn-outline-danger btn-sm"
              >
                X
              </button>
            </div>
          </div>
          <hr />
        </div>
        <button
          class="btn btn-sm btn-outline-primary mb-2"
          @click="newsletter.attachments.push({ filename: '', href: '' })"
          :disabled="newsletter.status === 'sent'"
          type="button"
        >
          Add attachment
        </button>
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            <button
              class="btn btn-sm btn-outline-primary"
              @click="sendNewsletter"
              :disabled="newsletter.status === 'sent'"
              type="submit"
            >
              📨 Send Newsletter
            </button>
          </div>
          <div>
            <button class="btn btn-sm btn-outline-primary" @click="sendTestMail" type="button">Send Testmail</button>
            <button
              class="btn btn-sm btn-success ml-3"
              type="submit"
              :disabled="newsletter.status === 'sent'"
              v-if="!saveInProgress"
            >
              Save Changes
            </button>
            <button class="btn btn-sm btn-success ml-3" disabled="disabled" type="submit" v-else>Saving...</button>
          </div>
        </div>
      </form>
      <div class="col-5">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h2 class="h4 mb-0">Recipients</h2>
          <DotMenu handleText="Select Group">
            <li @click="selectGroup()">All</li>
            <li @click="selectGroup('orders')">Orders</li>
            <li @click="selectGroup('accounting')">Accounting</li>
          </DotMenu>
        </div>
        <hr />
        <div>
          <div v-for="organization in organizations" :key="organization.id" class="mb-3">
            <h4 class="h6" v-if="organization.contactPersons">{{ organization.name }}</h4>
            <div v-for="(contactPerson, index) in organization.contactPersons" :key="organization.id + index">
              <div class="form-check">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="newsletter.recipients"
                    :value="contactPerson.email"
                    :id="organization.id + index"
                  />
                  <label class="custom-control-label" :for="organization.id + index">
                    {{ contactPerson.firstName }} {{ contactPerson.lastName }}
                    <span v-if="contactPerson.qualifiedForOrders" class="badge badge-pill badge-success ml-1"
                      >Orders</span
                    >
                    <span v-if="contactPerson.qualifiedForAccounting" class="badge badge-pill badge-info ml-1"
                      >Accounting</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, fb } from '@/shared/firebase'
import multiBatch from '@/shared/multiBatch'
import DotMenu from '@/shared/DotMenu'

export default {
  components: { DotMenu },
  data() {
    return {
      initialized: false,
      saveInProgress: false,
      newsletter: {
        recipients: [],
        subject: '',
        body: '',
        status: 'draft',
        attachments: []
      }
    }
  },
  computed: {
    newsletterId() {
      return this.$route.params.newsletterId
    },
    organizations() {
      const organizations = this.$store.state.organizations.filter(org => !org.demo)
      return _.sortBy(organizations, 'name')
    }
  },
  created() {
    db.doc(`newsletters/${this.newsletterId}`).onSnapshot(doc => {
      this.newsletter = doc.data()
      if (!doc.data().attachments) this.newsletter = { ...doc.data(), attachments: [] }
      this.initialized = true
    })
  },
  methods: {
    saveChanges() {
      if (this.newsletter.attachments && this.newsletter.attachments.length === 0) {
        this.newsletter.attachments = fb.firestore.FieldValue.delete()
      }
      this.saveInProgress = true
      db.doc(`newsletters/${this.newsletterId}`)
        .update(this.newsletter)
        .then(() => (this.saveInProgress = false))
    },
    selectGroup(group) {
      let contactPersons = _.flatten(this.organizations.map(o => o.contactPersons))
      if (group === 'accounting') contactPersons = contactPersons.filter(c => c.qualifiedForAccounting)
      if (group === 'orders') contactPersons = contactPersons.filter(c => c.qualifiedForOrders)
      this.$set(this.newsletter, 'recipients', [...new Set(contactPersons.map(c => c.email))])
    },
    sendTestMail() {
      const email = prompt('E-Mail')
      if (email) {
        const recipients = [{ email, salutationAndName: 'Herr Testempfänger' }]
        this.createMailDocs(recipients)
      }
    },
    sendNewsletter() {
      if (this.newsletter.status === 'sent') return alert('Newsletter has been already sent!')

      const contactPersons = []
      this.organizations.forEach(organization => {
        organization.contactPersons?.forEach(contactPerson => {
          const recipientIsSelected = this.newsletter.recipients.indexOf(contactPerson.email) !== -1
          const notYetAdded = typeof _.find(contactPersons, { email: contactPerson.email }) === 'undefined'
          if (recipientIsSelected && notYetAdded) contactPersons.push(contactPerson)
        })
      })
      const recipients = contactPersons.map(contactPerson => {
        return {
          email: contactPerson.email,
          salutationAndName: `${contactPerson.gender === 'female' ? 'Frau' : 'Herr'} ${
            contactPerson.title ? `${contactPerson.title} ` : ''
          }${contactPerson.lastName}`
        }
      })
      const confirmed = confirm(`Are you sure you want to send this newsletter to ${recipients.length} recipients?`)
      if (confirmed) {
        this.newsletter.status = 'sent'
        this.saveChanges()
        this.createMailDocs(recipients)
      }
    },
    createMailDocs(recipients) {
      const batch = new multiBatch()
      recipients.forEach(recipient => {
        const mailRef = db.collection('mail').doc()
        const mail = {
          createdAt: fb.firestore.FieldValue.serverTimestamp(),
          template: {
            data: {
              salutationAndName: recipient.salutationAndName,
              subject: this.newsletter.subject,
              body: this.newsletter.body.replace(/(?:\r\n|\r|\n)/g, '<br />')
            },
            name: 'newsletterMail'
          },
          to: recipient.email
        }
        if (this.newsletter.attachments.length > 0) _.set(mail, 'message.attachments', this.newsletter.attachments)
        batch.set(mailRef, mail)
      })
      batch.commit()
    }
  }
}
</script>
